import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import {
  Layout,
  Button,
  Section,
  Banner,
  ContactBanner,
  Weather,
  Footer,
  Header,
  HomeGraphicsSection,
  ContainerFacebook,
  ContainerTwitter,
  Column,
  Container,
  SEO,
  CommoditiesTable,
  TitleCard,
  Loader,
  Text,
  Graphic,
  Brokers as BrokersComponent
} from '../components'
import axios from 'axios'
import { styles } from '../Theme'
import { TitleSection } from '../components/Typography'

const getXMaxTicksLimit = () => {
  if (typeof window === 'undefined') {
    return 6
  }

  const widthScreen = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  if (widthScreen < 768) {
    return 3
  }

  if (widthScreen < 1200) {
    return 4
  }

  return 6
}

const HomePage = () => {
  const { t } = useTranslation()

  const [xMaxTicksLimit, setXMaxTicksLimit] = useState(getXMaxTicksLimit())
  const [dollarRequestError, setDollarRequestError] = useState(false)
  const [isDollarCotationLoading, setIsDollarCotationLoading] = useState(true)
  const [dollarCotation, setDollarCotation] = useState(null)
  const [dollarCotationTime, setDollarCotationTime] = useState(null)
  const [dollarFilterRange, setDollarFilterRange] = useState({
    filter: moment()
      .subtract(1, 'month')
      .format('MM-DD-YYYY'),
    now: moment.utc().format('MM-DD-YYYY')
  })

  const commotidies = [{ title: t('table:product') }, { title: t('table:last') }, { title: t('table:var') }]
  const products = [
    { name: t('table:rice'), price: 1258.88, variation: 10.1 },
    { name: t('table:soy'), price: 13.13, variation: -10.7 },
    { name: t('table:corn'), price: 15.67, variation: 27.5 },
    { name: t('table:wheat'), price: 14.47, variation: -27.3 }
  ]

  const makeDollarRequest = async range => {
    try {
      const res = await axios.get(
        `https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarPeriodo(dataInicial=@dataInicial,dataFinalCotacao=@dataFinalCotacao)?%40dataInicial='${range.filter}'&%40dataFinalCotacao='${range.now}'&%24format=json`
      )
      const formattedCotation = res.data.value.map(cotation => {
        return Number(Number(cotation.cotacaoVenda).toFixed(2))
      })
      const formattedCotationTime = res.data.value.map(cotation => {
        return moment(cotation.dataHoraCotacao).format('DD/MM/YY')
      })
      return [formattedCotation, formattedCotationTime]
    } catch {
      setDollarRequestError(true)
      setIsDollarCotationLoading(false)
    }
  }

  const getDollarCotation = async () => {
    setIsDollarCotationLoading(true)
    const res = await makeDollarRequest(dollarFilterRange)
    setIsDollarCotationLoading(false)
    setDollarCotation(res[0])
    setDollarCotationTime(res[1])
  }

  const setDollarRange = (quantity, periodTime) => {
    setDollarFilterRange({
      ...dollarFilterRange,
      filter: moment()
        .subtract(quantity, periodTime)
        .format('MM-DD-YYYY')
    })
  }

  useEffect(() => {
    getDollarCotation()
  }, [dollarFilterRange, xMaxTicksLimit])

  const mountIndicatorsBody = _ => {
    const indicatorsBody = []
    if (ricePrice.length) indicatorsBody.push({ name: t('table:rice'), price: ricePrice[1], variation: ricePrice[2] })
    if (soyPrice.length) indicatorsBody.push({ name: t('table:soy'), price: soyPrice[1], variation: soyPrice[2] })
    if (cornPrice.length) indicatorsBody.push({ name: t('table:corn'), price: cornPrice[1], variation: cornPrice[2] })
    if (wheatPrice.length)
      indicatorsBody.push({ name: t('table:wheat'), price: wheatPrice[1], variation: wheatPrice[2] })

    return indicatorsBody
  }

  return (
    <Layout>
      <SEO title={t('titlePages:home')} />
      <Header />
      <Banner text={t('banner:text')}>
        <Link to='/about'>
          <StyledButton>{t('home:about-button')} </StyledButton>
        </Link>
      </Banner>

      <SectionContainer>
        <HomeGraphicsSection />
      </SectionContainer>

      <SectionContainer>
        <StyledContainer>
          <CommoditiesTable
            title={t('table:title')}
            source={t('table:subtitle')}
            header={commotidies}
            body={products}
            error={false}
          />
          <GraphicWrapper>
            <GraphicTitle>
              <TitleCard color={styles.colors.red}>{t('graphics:title2')}</TitleCard>
              <SubTitle color={styles.colors.lightGrey}>{t('graphics:subtitle2')}</SubTitle>
            </GraphicTitle>
            {dollarRequestError ? (
              <WarningsContainer>
                <Text color={styles.colors.darkGrey}>{t('graphics:error')}</Text>
              </WarningsContainer>
            ) : isDollarCotationLoading ? (
              <WarningsContainer>
                <Loader />
              </WarningsContainer>
            ) : (
              <GraphicContent>
                <FiltersContainer>
                  <Button
                    variant='graphic'
                    checked={
                      dollarFilterRange.filter ===
                      moment()
                        .subtract(7, 'days')
                        .format('MM-DD-YYYY')
                    }
                    onClick={() => setDollarRange(7, 'days')}
                  >
                    1 {t('graphics:week')}
                  </Button>
                  <Button
                    variant='graphic'
                    checked={
                      dollarFilterRange.filter ===
                      moment()
                        .subtract(1, 'months')
                        .format('MM-DD-YYYY')
                    }
                    onClick={() => setDollarRange(1, 'months')}
                  >
                    1 {t('graphics:month')}
                  </Button>
                  <Button
                    variant='graphic'
                    checked={
                      dollarFilterRange.filter ===
                      moment()
                        .subtract(6, 'months')
                        .format('MM-DD-YYYY')
                    }
                    onClick={() => setDollarRange(6, 'months')}
                  >
                    6 {t('graphics:month')}
                  </Button>
                  <Button
                    variant='graphic'
                    checked={
                      dollarFilterRange.filter ===
                      moment()
                        .subtract(1, 'year')
                        .format('MM-DD-YYYY')
                    }
                    onClick={() => setDollarRange(1, 'year')}
                  >
                    1 {t('graphics:year')}
                  </Button>
                </FiltersContainer>
                <GraphicContainer>
                  <Graphic
                    values={dollarCotation}
                    label={dollarCotationTime}
                    yAxisSize={0.02}
                    xMaxTicksLimit={xMaxTicksLimit}
                  />
                </GraphicContainer>
              </GraphicContent>
            )}
          </GraphicWrapper>
        </StyledContainer>
      </SectionContainer>

      <Container>
        <Weather />
      </Container>
      <SectionContainer backgroundColor={styles.colors.white}>
        <Section title='home:brokers' text='home:brokers-text' />
        <BrokersComponent />
      </SectionContainer>
      <ContainerSocialNetwork>
        <StyledTitle color={styles.colors.red}>{t('home:social-network')}</StyledTitle>
        <SocialNetwork flexWrap='wrap'>
          <StyledColumn width={[1, 1, 1 / 2]} mb={[3, 3, 0]}>
            <ContainerTwitter />
          </StyledColumn>
          <StyledColumn width={[1, 1, 1 / 2]} mb={[3, 3, 0]}>
            <ContainerFacebook />
          </StyledColumn>
        </SocialNetwork>
      </ContainerSocialNetwork>
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

const SocialNetwork = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : '')};
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const ContainerSocialNetwork = styled(SectionContainer)`
  width: 100%;
  margin-bottom: 56px;
`

const StyledTitle = styled(TitleSection)`
  margin: 48px 0 24px 0;
`

const StyledButton = styled(Button)`
  min-width: 218px;
`
const StyledColumn = styled(Column)`
  justify-content: center;
  align-items: center;
`

// GRAPHICS CONTENT

const GraphicWrapper = styled.div`
  margin: 48px 12px 32px 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 767px) {
    &:last-of-type {
      margin-top: 0px;
    }
  }
`

const GraphicTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 14px 0;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const SubTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: 0;
  @media screen and (min-width: 1200px) {
    margin-left: 24px;
  }
`

const WarningsContainer = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GraphicContent = styled(Container)`
  width: 100%;
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  border-radius: 4px;
  padding: 0;
  margin: 0;
`

const FiltersContainer = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`

const GraphicContainer = styled.div`
  position: relative;
  width: 100%;
`

export default HomePage
